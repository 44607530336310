<div class="w-100 position-relative header d-flex flex-row align-items-center fixed-top">
    <div class="mw-25 ms-2" routerLink='/' (click)="setState('welcome')">
        <img class="rounded" src="/assets/logo.png" alt=""
            style="height:8vh;max-height:80px;min-height:60px;width:auto;">
    </div>
    <div class="ms-auto me-1 d-none d-md-block fs-6">{{(stateService.user$|async)?.email}}</div>
    <div class="btn btn-light border border-1 fs-1 d-md-none ms-auto ms-md-1 me-1"
        style="border-color:#ff003e!important;" routerLink='/home/account'><i class="bi bi-person-circle fucsia"></i>
    </div>
    <div class="btn btn-light border border-1 fs-1 d-md-none me-1" style="border-color:#ff003e!important;"
        (click)="logout()"><i class="bi bi-box-arrow-right fucsia"></i></div>
</div>