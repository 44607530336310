import { Component, OnInit } from '@angular/core';
import { StateService } from '../../services/state.service';
import * as Session from "supertokens-auth-react/recipe/session";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  public isCollapsed = true;

  constructor(public stateService: StateService) { }

  ngOnInit(): void {
  }

  setState(state: 'welcome' | 'pre' | 'event' | 'post') {
    this.stateService.appState$.next(state)
  }

  async logout() {
    await Session.signOut();
    window.location.reload();
  }
}
