<div class="w-100 h-100 d-flex" [class]="isFooter?'flex-row':'flex-column'">
    <div class="" [class]="isFooter?'w-100 d-flex flex-row justify-content-around':'d-grid gap-2 mt-4 mx-2'">
        <button class="btn btn-light fs-inherit" type="button" [class]="stateService.appState$.getValue() == 'pre'?'':'d-none'" (click)="closeSideMenu()" routerLink='/home/pre/plan'><i class="bi bi-map fucsia" [class]="isFooter?'fs-1':''"></i><span class="ms-2 fucsia" [class]="labeledFooter?'':'d-none d-md-inline'">Tour Plan</span></button>
        <button class="btn btn-light fs-inherit" type="button" [class]="stateService.appState$.getValue() == 'pre'?'':'d-none'" (click)="closeSideMenu()" routerLink='/home/pre'><i class="bi bi-question-square fucsia" [class]="isFooter?'fs-1':''"></i><span class="ms-2 fucsia" [class]="labeledFooter?'':'d-none d-md-inline'">Quiz</span></button>
        <button class="btn btn-light fs-inherit" type="button" [class]="stateService.appState$.getValue() == 'event'?'':'d-none'" (click)="closeSideMenu()" routerLink='/home/event'><i class="bi bi-images fucsia" [class]="isFooter?'fs-1':''"></i><span class="ms-2 fucsia" [class]="labeledFooter?'':'d-none d-md-inline'">Foto</span></button>
        <button class="btn btn-light fs-inherit" type="button" [class]="stateService.appState$.getValue() == 'post'?'':'d-none'" (click)="closeSideMenu()" routerLink='/home/post'><i class="bi bi-clipboard2-data fucsia" [class]="isFooter?'fs-1':''"></i><span class="ms-2 fucsia" [class]="labeledFooter?'':'d-none d-md-inline'">Sondaggio</span></button>
        <button class="btn btn-light fs-inherit" type="button" [class]="stateService.appState$.getValue() == ('event') || stateService.appState$.getValue() == ('post') ?'':'d-none'" (click)="closeSideMenu()" routerLink='/home/post/ranking'><i class="bi bi-trophy fucsia" [class]="isFooter?'fs-1':''"></i><span class="ms-2 fucsia" [class]="labeledFooter?'':'d-none d-md-inline'">Classifica</span></button>
        <button class="btn btn-light fs-inherit" type="button" [class]="isFooter?'d-none':''" (click)="closeSideMenu()" routerLink='account'><i class="bi bi-person-circle fucsia" [class]="isFooter?'fs-1':''"></i><span class="ms-2 d-none d-md-inline fucsia">Account</span></button>
        <button class="btn btn-light fs-inherit" type="button" [class]="isFooter?'d-none':''" (click)="logout()"><i class="bi bi-box-arrow-right fucsia" [class]="isFooter?'fs-1':''"></i><span class="ms-2 d-none d-md-inline fucsia">Logout</span></button>
    </div>
</div>