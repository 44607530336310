import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { StateService } from '../../services/state.service';
import { AuthService, userInfo } from 'src/app/auth/auth.service';
import * as Session from "supertokens-auth-react/recipe/session";

@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss']
})
export class SideMenuComponent implements OnInit {

  @Input('isFooter') isFooter: boolean = false;
  @Output() collapseMenu: EventEmitter<void> = new EventEmitter();
  labeledFooter = true;

  public session = false;
  public userInfo = userInfo;
  public userId = "";

  constructor(
    public stateService: StateService,
    private authService: AuthService
  ) { }

  async getUserInfo() {
    //this.userId = await Session.getUserId();
    //this.authService.getUserInfo();
  }

  async logout() {
    await Session.signOut();
    window.location.reload();
  }

  ngOnInit(): void {
  }

  closeSideMenu() {
    this.collapseMenu.emit()
  }
}
